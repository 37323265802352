import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="GitHub Repos" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/rvente/nlgs-research"
        }}>{`Prose and Structured data bijection, Earned A+ for Graduate Final Project`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/rvente/TeXNet.ai"
        }}>{`TeXNet.ai, Earned A+ for AI Final Project`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/rvente/PerFi"
        }}>{`PerFi, React Capstone Project for TTP Residency`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      